import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import Webcam from 'react-webcam';
import { UserContext } from '../../utils/context/UserContext';
import TimeKeepingModal from './TimeKeepingModal';

export default function TimeIn() {
  const { profile } = useContext(UserContext);

  const webcamRef = useRef(null);
  let mediaStream = null;

  const [isCameraOpen, setIsCameraOpen] = useState(false);
  const [time, setTime] = useState('');

  const [timeInClock, setTimeInClock] = useState('');
  const [image, setImage] = useState(null);

  const [isTimeKeepingModalOpen, setIsTimeKeepingModalOpen] = useState(false);

  const startCamera = () => {
    setIsCameraOpen(true);
    navigator.mediaDevices
      .getUserMedia({ video: true })
      .then((stream) => {
        mediaStream = stream;
        const videoElement = webcamRef.current.video;
        videoElement.srcObject = stream;
      })
      .catch((error) => {
        console.log('Error accessing camera:', error);
      });
  };

  const stopCamera = () => {
    if (mediaStream) {
      mediaStream.getTracks().forEach((track) => {
        track.stop();
      });
    }
    setIsCameraOpen(false);
  };

  const captureImage = () => {
    const imageSrc = webcamRef.current.getScreenshot();
    setImage(imageSrc);
    stopCamera();

    setTimeInClock(time);
    setIsTimeKeepingModalOpen(true);
  };

  const handleTimeInUser = () => {
    console.log('profile', profile);
    console.log('time', timeInClock);
    console.log('image', image);
    setIsTimeKeepingModalOpen(false);
  };

  useEffect(() => {
    // Function to update the time every second
    const updateTime = () => {
      const currentTime = new Date().toLocaleString('en-PH', {
        timeZone: 'Asia/Manila',
      });
      setTime(currentTime);
    };

    // Set interval to update time every second
    const intervalId = setInterval(updateTime, 1000);

    // Clean up interval on component unmount
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <Container className="pt-3">
      <Row>
        <Col>
          <h2>Time-In</h2>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col className="text-center" xs={12}>
          <div>
            <h5>Current Time: </h5>
            <p className="text-muted">{time}</p>
          </div>
          {isCameraOpen && <Button onClick={captureImage}>Time In!</Button>}
          {!isCameraOpen && (
            <Button variant="success" onClick={startCamera}>
              Start by {image === null ? 'Take' : 'Re-Taking'} a selfie!
            </Button>
          )}
          <div className="mt-3">
            {isCameraOpen && <Webcam ref={webcamRef} className="w-75" />}{' '}
            {image !== null && !isCameraOpen && (
              <img src={image} alt="selfie" className="w-75" />
            )}
          </div>
        </Col>
      </Row>
      <TimeKeepingModal
        isModalOpen={isTimeKeepingModalOpen}
        setIsModalOpen={setIsTimeKeepingModalOpen}
        handleConfirm={handleTimeInUser}
        image={image}
        time={timeInClock}
        profile={profile}
      />
    </Container>
  );
}
