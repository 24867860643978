export const cashAdvanceReportHeader = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    sorter: (a, b) => a.id - b.id,
    fixed: 'left',
  },
  {
    title: 'Employee',
    dataIndex: 'field1',
    key: 'field1',
    sorter: (a, b) => a.field1.localeCompare(b.field1),
  },
  {
    title: 'Date',
    dataIndex: 'field2',
    key: 'field2',
    sorter: (a, b) => a.field2.localeCompare(b.field2),
  },
  {
    title: 'Time',
    dataIndex: 'field3',
    key: 'field3',
    sorter: (a, b) => a.field3.localeCompare(b.field3),
  },
  {
    title: 'Approved By',
    dataIndex: 'field4',
    key: 'field4',
    sorter: (a, b) => a.field3.localeCompare(b.field3),
  },
  {
    title: 'Time Approved',
    dataIndex: 'field5',
    key: 'field5',
    sorter: (a, b) => a.field3.localeCompare(b.field3),
  },
];
