import React, { useContext, useEffect, useState } from 'react';
import CustomerSelection from './CustomerSelection';
import CategorySelection from './CategorySelection';
import { Badge, Button, Col, Container, Row } from 'react-bootstrap';
import {
  CustomerDefault,
  CategoryDefault,
  ProductDefault,
  BranchDefault,
} from '../../utils/data/_index';
import { GlobalContext } from '../../App';
import BranchSelection from './BranchSelection';
import ConfirmationPage from './ConfirmationPage';
import { UserContext } from '../../utils/context/UserContext';
import { getBranchById } from '../../utils/api/BranchAPI';
import ContainerSelection from './ContainerSelection';
import { LoadingModal } from '../../components';
import { RiShoppingCartFill } from 'react-icons/ri';
import RefillModal from './RefillModal';
import CartModal from './CartModal';

export default function SalesOrder() {
  const { isLoggedIn } = useContext(GlobalContext);
  const {
    profile,
    isErrorAlertShown,
    setIsErrorAlertShown,
    errorMessage,
    setErrorMessage,
  } = useContext(UserContext);

  const [selectedBranch, setSelectedBranch] = useState(BranchDefault);
  const [selectedCustomer, setSelectedCustomer] = useState(CustomerDefault);
  const [selectedContainer, setSelectedContainer] = useState(ProductDefault);

  const [pageState, setPageState] = useState(0);

  const [cart, setCart] = useState([]);

  const [isLoadingModalOpen, setIsLoadingModalOpen] = useState(false);
  const [isRefillModalOpen, setIsRefillModalOpen] = useState(false);
  const [isCartModalOpen, setIsCartModalOpen] = useState(false);

  const onSelectBranch = (branch) => {
    setSelectedBranch(branch);
  };

  const onSelectCustomer = (customer) => {
    setSelectedCustomer(customer);
    setPageState(pageState + 1);
  };

  const onSelectContainer = (container) => {
    setSelectedContainer(container);
    setIsRefillModalOpen(true);
  };

  const loadBranchId = async () => {
    const branch = await getBranchById(profile.branchCode);
    if (branch.isSuccessful) {
      setSelectedBranch(branch.data[0]);
    }
  };

  const handleConfirmOrder = () => {
    setIsLoadingModalOpen(true);

    const orderList = cart.map((item) => ({
      productId: item.container.id,
      productQty: item.refillRequest,
    }));

    const orderInfo = {
      orderBranch: profile.branchCode,
      orderCustomer: selectedCustomer.id,
      mode: 0,
      productList: orderList,
    };

    console.log(JSON.stringify(orderInfo));

    setTimeout(() => {
      setIsLoadingModalOpen(false);
      resetValues();
    }, 1500);
  };

  const resetValues = () => {
    setSelectedCustomer(CustomerDefault);
    setSelectedContainer(ProductDefault);
    setCart([]);
    setPageState(0);
  };

  const handleAddToCart = (orderInfo) => {
    setCart((oldCart) => [...oldCart, orderInfo]);
  };

  useEffect(() => {
    if (isLoggedIn && profile.branchCode !== '') {
      loadBranchId();
    }
  }, [isLoggedIn, profile]);

  return (
    <>
      {!isLoggedIn && selectedBranch.id === '' && (
        <BranchSelection onSelectBranch={onSelectBranch} />
      )}
      {selectedBranch.id !== '' && (
        <>
          {pageState !== 0 && (
            <Row className="align-items-center">
              <Col xs={2}>
                <Button
                  onClick={() => setPageState(pageState - 1)}
                  variant="outline-primary"
                >
                  Back
                </Button>
              </Col>
              <Col xs={8}></Col>
              {pageState === 1 && cart.length !== 0 && (
                <Col xs={2}>
                  <Row>
                    <Col className="text-end">
                      <Button
                        variant="transparent"
                        className="p-0 m-0"
                        onClick={() => setIsCartModalOpen(true)}
                      >
                        <RiShoppingCartFill size={36} color="#0D6EFD" />
                        <Badge bg="info">{cart.length}</Badge>
                      </Button>
                    </Col>
                    <Col>
                      <Button
                        onClick={() => setPageState(pageState + 1)}
                        variant="outline-success"
                      >
                        Continue
                      </Button>
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
          )}
          {pageState === 0 && (
            <CustomerSelection onSelectCustomer={onSelectCustomer} />
          )}
          {/* {pageState === 1 && (
            <CategorySelection
              customer={selectedCustomer}
              onSelectCategory={onSelectCategory}
            />
          )} */}
          {pageState === 1 && (
            <ContainerSelection
              customer={selectedCustomer}
              onSelectContainer={onSelectContainer}
            />
          )}
          {pageState === 2 && (
            <ConfirmationPage
              customer={selectedCustomer}
              branch={selectedBranch}
              orderInfo={cart}
              handleConfirmOrder={handleConfirmOrder}
            />
          )}
        </>
      )}
      <LoadingModal isModalOpen={isLoadingModalOpen} />
      <RefillModal
        isModalOpen={isRefillModalOpen}
        setIsModalOpen={setIsRefillModalOpen}
        container={selectedContainer}
        handleAddToCart={handleAddToCart}
      />
      <CartModal
        isModalOpen={isCartModalOpen}
        setIsModalOpen={setIsCartModalOpen}
        cart={cart}
        setCart={setCart}
      />
    </>
  );
}
