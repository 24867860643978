import React, { useState } from 'react';
import { Alert, Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { getCustomerFields } from '../../utils/fields/customer';
import InputControl from '../InputControl/InputControl';
import { CustomerDefault } from '../../utils/data/_index';

export default function CustomerModal({ isModalOpen, setIsModalOpen }) {
  const [isRequesting, setIsRequesting] = useState(false);

  const [customerState, setCustomerState] = useState(CustomerDefault);

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsRequesting(true);
    console.log('submit', customerState);
  };

  return (
    <Modal
      show={isModalOpen}
      onHide={() => setIsModalOpen(false)}
      backdrop="static"
      keyboard="false"
      size="lg"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Add customer details</Modal.Title>
      </Modal.Header>
      {/* <Alert
        variant="danger"
        show={isErrorAlertShown}
        onClose={setIsErrorAlertShown}
        dismissible
        className="my-0"
      >
        {errorMessage}
      </Alert> */}
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          {getCustomerFields(isRequesting, customerState, setCustomerState).map(
            (customerField, index) => (
              <InputControl
                key={index}
                type={customerField.type}
                label={customerField.label}
                placeholder={customerField.placeholder}
                autoFocus={customerField.autofocus}
                required={customerField.required}
                disabled={customerField.disabled}
                value={customerField.value}
                onChange={customerField.setValue}
                dropdownOptions={customerField.dropdownOptions}
              />
            )
          )}
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setIsModalOpen(false)}
              className="p-2 w-25"
            >
              Close
            </Button>
            <Button
              variant="success"
              type="submit"
              className="p-2 w-25"
              disabled={isRequesting}
            >
              {isRequesting ? 'Saving...' : 'Save'}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
