import React, { useContext } from 'react';
import { Button, Card, Col, Container, Row } from 'react-bootstrap';
import { GlobalContext } from '../../App';
import { UserContext } from '../../utils/context/UserContext';

export default function ConfirmationPage({
  customer,
  branch,
  orderInfo,
  handleConfirmOrder,
}) {
  return (
    <Container>
      <Row>
        <Col sm={{ span: 6, offset: 3 }} className="text-center">
          <h2>
            Hi, <strong>{customer.firstName}</strong>.
          </h2>
          <span className="text-muted">
            Kindly confirm order details below.
          </span>
        </Col>
      </Row>
      <Row className="text-center mt-2">
        <Col>
          <Button
            size="lg"
            variant="primary"
            className="my-4 py-3 w-50"
            onClick={() => handleConfirmOrder()}
          >
            Confirm!
          </Button>
        </Col>
      </Row>
      <Row className="mt-4">
        <Col>
          <Card className="p-0" style={{ width: '22rem' }}>
            <Card.Header>Customer Info:</Card.Header>
            <Card.Body>
              <Card.Title className="mb-3">
                Name:
                <strong>
                  {' '}
                  {customer.firstName} {customer.middleName} {customer.lastName}
                </strong>
              </Card.Title>
              {customer.address && (
                <Card.Text className="mb-1">
                  Address: <strong>{customer.address}</strong>
                </Card.Text>
              )}
              {customer.mobileNumber && (
                <Card.Text className="mb-1">
                  Contact Number: <strong>{customer.mobileNumber}</strong>
                </Card.Text>
              )}
              {branch.name && (
                <Card.Text className="mb-1">
                  Branch: <strong>{branch.name}</strong>
                </Card.Text>
              )}
              {branch.address && (
                <Card.Text className="mb-1">
                  Branch address: <strong>{branch.address}</strong>
                </Card.Text>
              )}
              {branch.contactNumber && (
                <Card.Text className="mb-1">
                  Branch contact number: <strong>{branch.contactNumber}</strong>
                </Card.Text>
              )}
            </Card.Body>
          </Card>
        </Col>
        <Col>
          <Card className="p-0" style={{ width: '22rem' }}>
            <Card.Header>Order Info:</Card.Header>
            <Card.Body>
              {/* <Card.Title className="mb-3">
                <strong>Cart</strong>
              </Card.Title> */}
              {orderInfo.map((order, index) => (
                <div key={index}>
                  <Card.Text className="mb-1">
                    {index + 1}) Container:{' '}
                    <strong>{order.container.name}</strong> -
                  </Card.Text>
                  <Card.Text className="mb-1 ms-4">
                    Refill: <strong>{order.refillRequest}</strong>
                  </Card.Text>
                </div>
              ))}
            </Card.Body>
          </Card>
        </Col>
        {/* <Col>
          <Card className="p-0" style={{ width: '22rem' }}>
            <Card.Header>Order Info:</Card.Header>
            <Card.Body>
              <Card.Title className="mb-3">
                Product: <strong>{product.name}</strong>
              </Card.Title>
              <Card.Text className="mb-1">
                Description: <strong>{product.description}</strong>
              </Card.Text>
              <Card.Text className="mb-1">
                Code: <strong>{product.code}</strong>
              </Card.Text>
              <Card.Text className="mb-1">
                Category: <strong>{product.category}</strong>
              </Card.Text>
              <Card.Text className="mb-1">
                Price: <strong>&#8369;{product.price}</strong>
              </Card.Text>
            </Card.Body>
          </Card>
        </Col> */}
      </Row>
    </Container>
  );
}
