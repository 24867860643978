import axios from 'axios';
import { getToken } from '../token';
import { API_CALL_ERROR, CATEGORIES_ENDPOINT } from './APIConstants';

export async function getCategories(requestorId, branchCode) {
  const authToken = getToken();

  try {
    const { data } = await axios.get(CATEGORIES_ENDPOINT, {
      params: {
        branchCode: branchCode,
        requestorId: requestorId,
        authToken: authToken,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return data;
  } catch (error) {
    console.log(error);
    return { isSuccessful: false, data: API_CALL_ERROR };
  }
}

export async function getCategoryById(requestorId, branchCode, customerId) {
  const authToken = getToken();

  try {
    const { data } = await axios.get(CATEGORIES_ENDPOINT, {
      params: {
        branchCode: branchCode,
        customerId: customerId,
        requestorId: requestorId,
        authToken: authToken,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return data;
  } catch (error) {
    console.log(error);
    return { isSuccessful: false, data: API_CALL_ERROR };
  }
}

export async function getCategoryBySearch(requestorId, branchCode, search) {
  const authToken = getToken();
  try {
    const { data } = await axios.get(CATEGORIES_ENDPOINT, {
      params: {
        branchCode: branchCode,
        search: search,
        requestorId: requestorId,
        authToken: authToken,
      },
      headers: {
        'Content-Type': 'application/json',
      },
    });
    return data;
  } catch (error) {
    console.log(error);
    return { isSuccessful: false, data: API_CALL_ERROR };
  }
}
