import React from 'react';
import { Button, Modal } from 'react-bootstrap';

export default function ConfirmationModal({
  isModalOpen,
  setIsModalOpen,
  handleConfirm,
  variant,
  description,
}) {
  return (
    <Modal
      show={isModalOpen}
      onHide={() => setIsModalOpen(false)}
      backdrop="static"
      keyboard="false"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>{description}</Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setIsModalOpen(false)}>
          Close
        </Button>
        <Button variant={variant} onClick={handleConfirm}>
          {variant === 'danger' ? 'Delete' : 'Continue'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
