export const HOME_ROUTE = '/';

// Sales order routes
export const SALES_ORDER_ROUTE = '/sales-order';

// Sales entry routes
export const SALES_ENTRY_ROUTE = '/sales-entry';

// Users routes
export const USER_MANAGEMENT_ROUTE = '/user-management';

// Time Keeping routes
export const TIME_KEEPING_ROUTE = '/time-keeping';

// Reports routes
export const BULLET_TANK_HISTORY_REPORT_ROUTE = '/bullet-tank-history-report';
export const SALES_HISTORY_REPORT_ROUTE = '/sales-history-report';
export const CUSTOMER_HISTORY_REPORT_ROUTE = '/customer-history-report';
export const CASH_ADVANCE_REPORT_ROUTE = '/cash-advance-report';
export const INCOME_STATEMENT_REPORT_ROUTE = '/income-statement-report';
export const EXPENSE_REPORT_ROUTE = '/expense-report';
export const OUTLET_HISTORY_REPORT_ROUTE = '/outlet-history-report';
export const TIME_KEEPING_REPORT_ROUTE = '/time-keeping-report';

// Maintenance routes
export const BULLET_TANK_MAINTENANCE_ROUTE = '/bullet-tank-maintenance';
export const CUSTOMER_LIST_MAINTENANCE_ROUTE = '/customer-list-maintenance';
export const OUTLET_MAINTENANCE_ROUTE = '/outlet-maintenance';
export const ACCESS_LEVEL_MAINTENANCE_ROUTE = '/access-level-maintenance';
export const BRANCH_MAINTENANCE_ROUTE = '/branch-maintenance';
export const CATEGORY_MAINTENANCE_ROUTE = '/category-maintenance';
export const PRODUCT_MAINTENANCE_ROUTE = '/product-maintenance';

// Outlet routes
export const PURCHASE_ORDER_OUTLET_ROUTE = '/purchase-order-outlet';
export const CASH_ADVANCE_OUTLET_ROUTE = '/cash-advance-outlet';
export const CUSTOMER_ENTRY_OUTLET_ROUTE = '/customer-entry-outlet';

// Inventory routes
export const BULLET_TANK_INVENTORY_ROUTE = '/bullet-tank-inventory';
export const INCOMING_INVENTORY_ROUTE = '/incoming-inventory';

// Time keeping routes
export const TIME_IN_ROUTE = '/time-in'
export const TIME_OUT_ROUTE = '/time-out'