export const saveToken = (token) => {
  if (token !== '') {
    localStorage.setItem('auth-token', token);
  }
};

export const getToken = () => {
  const token = localStorage.getItem('auth-token');
  if (token !== null || token !== undefined || token !== '') {
    return token;
  } else {
    return '';
  }
};

export const removeToken = () => {
  localStorage.removeItem('auth-token');
};

export const saveValidUntil = (validUntil) => {
  if (validUntil !== '') {
    localStorage.setItem('valid-until', validUntil);
  }
};

const getValidUntil = () => {
  const validUntil = localStorage.getItem('valid-until');
  if (validUntil !== null || validUntil !== undefined || validUntil !== '') {
    return validUntil;
  } else {
    return '';
  }
};

export const removeValidUntil = () => {
  localStorage.removeItem('valid-until');
};

export const validateToken = () => {
  const validUntil = getValidUntil();
  const validUntilDate = new Date(validUntil);
  const now = new Date();

  if (validUntilDate < now) {
    return false;
  }
  return true;
};
