import React, { useEffect, useState } from 'react';
import { Alert, Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { USER_DEFAULT_PASSWORD } from '../../utils/constants';
import { UserDefault } from '../../utils/data/_index';

export default function UserModal({
  isUserModalShown,
  setIsUserModalShown,
  isNewEntry,
  isRequesting,
  isErrorAlertShown,
  setIsErrorAlertShown,
  currentUserState = UserDefault,
  setCurrentUserState,
  handleSubmit,
  accessLevelOptions,
  profile,
  errorMessage,
}) {
  const [hasChanges, setHasChanges] = useState(false);

  useEffect(() => {
    setHasChanges(false);
    if (isNewEntry) {
      setCurrentUserState({
        ...currentUserState,
        password: USER_DEFAULT_PASSWORD,
        updatedBy: profile.id,
        branchCode: profile.branchCode,
      });
    } else {
      setCurrentUserState({
        ...currentUserState,
        updatedBy: profile.id,
      });
    }
  }, [isUserModalShown]);

  return (
    <Modal
      show={isUserModalShown}
      onHide={() => setIsUserModalShown(false)}
      backdrop="static"
      keyboard="false"
      size="lg"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {isNewEntry ? 'Register User' : 'Update User'}
        </Modal.Title>
      </Modal.Header>
      <Alert
        variant="danger"
        show={isErrorAlertShown}
        onClose={setIsErrorAlertShown}
        dismissible
        className="my-0"
      >
        {errorMessage}
      </Alert>
      <Modal.Body>
        <Form onSubmit={handleSubmit}>
          <Row>
            <Col xs={6}>
              <Form.Group className="mb-3" controlId="formUsername">
                <Form.Label>Username</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="username"
                  autoFocus
                  value={currentUserState.username}
                  required={true}
                  disabled={isRequesting || !isNewEntry}
                  onChange={(e) => {
                    setHasChanges(true);
                    const value = e.target.value;
                    setCurrentUserState({
                      ...currentUserState,
                      username: value,
                    });
                  }}
                />
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col>
              <Form.Group className="mb-3" controlId="formFirstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Juan"
                  value={currentUserState.firstName}
                  required={true}
                  disabled={isRequesting}
                  onChange={(e) => {
                    setHasChanges(true);
                    const value = e.target.value;
                    setCurrentUserState({
                      ...currentUserState,
                      firstName: value,
                    });
                  }}
                  className="text-capitalize"
                />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group className="mb-3" controlId="formLastName">
                <Form.Label>Last Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Dela Cruz"
                  value={currentUserState.lastName}
                  required={true}
                  disabled={isRequesting}
                  onChange={(e) => {
                    setHasChanges(true);
                    const value = e.target.value;
                    setCurrentUserState({
                      ...currentUserState,
                      lastName: value,
                    });
                  }}
                  className="text-capitalize"
                />
              </Form.Group>
            </Col>
          </Row>
          <Form.Group className="mb-3" controlId="category">
            <Form.Label>Access level</Form.Label>
            <Form.Select
              required={true}
              value={currentUserState.accessLevel}
              disabled={isRequesting}
              onChange={(e) => {
                setHasChanges(true);
                const value = e.target.value;
                setCurrentUserState({
                  ...currentUserState,
                  accessLevel: value,
                });
              }}
            >
              <option value={0}>Select Level</option>
              {accessLevelOptions.map((level) => (
                <option key={level.accessCode} value={level.accessCode}>
                  {level.accessDescription}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
          <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setIsUserModalShown(false)}
              className="p-2 w-25"
            >
              Close
            </Button>
            <Button
              variant="success"
              type="submit"
              className="p-2 w-25"
              disabled={isRequesting || !hasChanges}
            >
              {isRequesting ? 'Saving...' : 'Save'}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal.Body>
    </Modal>
  );
}
