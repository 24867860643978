export const incomeStatementReportHeader = [
  {
    title: 'Net Sales',
    dataIndex: 'id',
    key: 'id',
    sorter: (a, b) => a.id - b.id,
    fixed: 'left',
  },
  {
    title: 'Gross Sales',
    dataIndex: 'field1',
    key: 'field1',
    sorter: (a, b) => a.field1.localeCompare(b.field1),
  },
  {
    title: 'Operational Cost',
    dataIndex: 'field2',
    key: 'field2',
    sorter: (a, b) => a.field2.localeCompare(b.field2),
  },
  {
    title: 'Income Tax Expense',
    dataIndex: 'field3',
    key: 'field3',
    sorter: (a, b) => a.field3.localeCompare(b.field3),
  },
  {
    title: 'Interest Expense',
    dataIndex: 'field3',
    key: 'field3',
    sorter: (a, b) => a.field3.localeCompare(b.field3),
  },
];
