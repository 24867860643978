import { DatePicker, Table } from 'antd';
import React, { useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { salesHistoryReportHeader } from '../../utils/TableData/Report/SalesHistoryReport';

export default function SalesHistoryReport() {
  const [isLoading, setIsLoading] = useState(false);

  const [selectedFromDate, setSelectedFromDate] = useState('');
  const [selectedToDate, setSelectedToDate] = useState('');

  const [localSalesHistoryData, setLocalSalesHistoryData] = useState([]);
  return (
    <Container className="pt-3">
      <h2>Sales History - Report</h2>
      <hr />
      <Row>
        <Col>
          <DatePicker.RangePicker
            onChange={(dates) => {
              const [start, end] = dates;
              setSelectedFromDate(start.format('YYYY-MM-DD'));
              setSelectedToDate(end.format('YYYY-MM-DD'));
            }}
            format="YYYY-MM-DD"
            placeholder={['Start Date', 'End Date']}
            style={{ height: '40px', width: '100%' }}
            disabled={isLoading}
          />
        </Col>
        <Col>
          <Button
            variant="secondary"
            disabled={
              isLoading || selectedFromDate === '' || selectedToDate === ''
            }
            onClick={() => {
              setIsLoading(true);
              setTimeout(() => {
                setIsLoading(false);
              }, 3000);
            }}
            className="mb-3 w-100"
          >
            {isLoading ? 'Loading...' : 'Process'}
          </Button>
        </Col>
      </Row>
      <Table
        columns={salesHistoryReportHeader}
        dataSource={localSalesHistoryData}
        size="small"
        bordered="true"
        scroll={{ x: true }}
        loading={isLoading}
      />
    </Container>
  );
}
