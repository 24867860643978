import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';

import { FcSearch } from 'react-icons/fc';
import {
  ConfirmationModal,
  CustomerCard,
  CustomerModal,
  InputControl,
} from '../../components';
import { UserContext } from '../../utils/context/UserContext';
import { CustomerDefault } from '../../utils/data/_index';
import { GlobalContext } from '../../App';
import { BsPersonPlusFill, BsPlusCircleDotted } from 'react-icons/bs';

export default function CustomerSelection({ onSelectCustomer }) {
  const { setIsToastOpen, setToast } = useContext(GlobalContext);
  const {
    profile,
    customersList,
    loadAllCustomers,
    loadCustomersBySearch,
    loadCustomerById,
    isErrorAlertShown,
    setIsErrorAlertShown,
    errorMessage,
    setErrorMessage,
  } = useContext(UserContext);

  const [isLoading, setIsLoading] = useState(true);

  const [localCustomersList, setLocalCustomersList] = useState([
    CustomerDefault,
  ]);

  const [searchValue, setSearchValue] = useState('');
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState(false);
  const [isCustomerEntryModalOpen, setisCustomerEntryModalOpen] =
    useState(false);

  const handleSearchCustomer = async (e) => {
    e.preventDefault();
    const searchValue = e.target.value;
    setSearchValue(searchValue);
    if (searchValue.length >= 3) {
      setIsLoading(true);
      await loadCustomersBySearch(searchValue);
      setIsLoading(false);
    }
  };

  const handleConfirmation = () => {
    const anonymousUser = CustomerDefault;
    anonymousUser.id = 0;
    anonymousUser.firstName = 'Anonymous';
    onSelectCustomer(anonymousUser);
    setIsConfirmationModalOpen(false);
  };

  const onCustomerSelect = (customer) => {
    setLocalCustomersList([]);
    onSelectCustomer(customer);
  };

  useEffect(() => {
    setLocalCustomersList(customersList);
  }, [customersList]);
  return (
    <>
      <Row>
        <Col xs={{ span: 4, offset: 4 }} className="text-center">
          <InputControl
            placeholder={'Start searching your name!'}
            value={searchValue}
            onChange={handleSearchCustomer}
            children={<FcSearch size={20} />}
          />
          <Button
            variant="link"
            className="text-muted mb-3 text-decoration-none"
            onClick={() => setIsConfirmationModalOpen(true)}
          >
            Continue as anonymous customer?
          </Button>
        </Col>
      </Row>
      <Container>
        <Row>
          <Col className="my-auto" xs={1}>
            <BsPlusCircleDotted
              size={80}
              color="gray"
              cursor={'pointer'}
              onClick={() => setisCustomerEntryModalOpen(true)}
            />
          </Col>
          {localCustomersList.map((customer, index) => (
            <CustomerCard
              key={index}
              customerDetails={customer}
              onSelect={onCustomerSelect}
            />
          ))}
        </Row>
      </Container>
      <ConfirmationModal
        isModalOpen={isConfirmationModalOpen}
        setIsModalOpen={setIsConfirmationModalOpen}
        variant={'primary'}
        handleConfirm={handleConfirmation}
        description={'Are you sure to proceed with no customer account?'}
      />
      <CustomerModal
        isModalOpen={isCustomerEntryModalOpen}
        setIsModalOpen={setisCustomerEntryModalOpen}
      />
    </>
  );
}
