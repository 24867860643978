import './App.css';
import './Colors.css';

//fonts
import './assets/fonts/Poppins/Poppins-Light.ttf';
import './assets/fonts/Poppins/Poppins-Thin.ttf';
import './assets/fonts/Poppins/Poppins-Regular.ttf';
import './assets/fonts/Poppins/Poppins-SemiBold.ttf';
import './assets/fonts/Poppins/Poppins-Medium.ttf';
import './assets/fonts/Poppins/Poppins-Bold.ttf';
import { BrowserRouter, HashRouter } from 'react-router-dom';
import { createContext, useEffect, useState } from 'react';
import MainRoute from './utils/router/MainRoute';
import AuthRoute from './utils/router/AuthRoute';
import { UserProvider } from './utils/context/UserContext';
import { decryptValue, encryptValue } from './utils/encryption';
import { IS_LOGGED_IN_KEY } from './utils/constants';
import { validateToken } from './utils/token';
import { CustomToast } from './components';

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const [isToastOpen, setIsToastOpen] = useState(false);
  const [toast, setToast] = useState({});

  const globalContext = {
    isLoggedIn,
    setIsLoggedIn,
    setIsToastOpen,
    setToast,
  };

  useEffect(() => {
    const loginState = localStorage.getItem(IS_LOGGED_IN_KEY);
    if (loginState !== null && loginState !== undefined) {
      const decryptedValue = decryptValue(loginState);
      if (decryptedValue === 'true') {
        if (validateToken()) {
          setIsLoggedIn(true);
        }
      }
    }
  }, []);

  useEffect(() => {
    const encryptedValue = encryptValue(isLoggedIn.toString());
    localStorage.setItem(IS_LOGGED_IN_KEY, encryptedValue);
  }, [isLoggedIn]);

  return (
    <GlobalContext.Provider value={globalContext}>
      <UserProvider>
        <HashRouter>
          <div className="App">
            {isLoggedIn ? (
              <>
                <MainRoute />
                <CustomToast
                  isToastShown={isToastOpen}
                  setIsToastShown={setIsToastOpen}
                  toast={toast}
                />
              </>
            ) : (
              <AuthRoute />
            )}
          </div>
        </HashRouter>
      </UserProvider>
    </GlobalContext.Provider>
  );
}

export default App;

export const GlobalContext = createContext();
