import { FaUsers } from 'react-icons/fa';
import { AiOutlineFieldTime, AiOutlineStock } from 'react-icons/ai';
import { BsCashCoin } from 'react-icons/bs';
import { MdOutlineInventory2, MdOutlinePointOfSale } from 'react-icons/md';
import { SiOpslevel } from 'react-icons/si';
import { TfiPencilAlt } from 'react-icons/tfi';
import {
  HiOutlineCash,
  HiOutlinePencilAlt,
  HiOutlineUsers,
} from 'react-icons/hi';
import {
  TbBuildingStore,
  TbReportAnalytics,
  TbReportMoney,
} from 'react-icons/tb';
import {
  BiCategory,
  BiCylinder,
  BiGasPump,
  BiGitBranch,
  BiStore,
  BiTimer,
} from 'react-icons/bi';
import { GrDocumentUser, GrVmMaintenance } from 'react-icons/gr';
import { GiCash, GiExpense } from 'react-icons/gi';
import {
  ACCESS_LEVEL_MAINTENANCE_ROUTE,
  BRANCH_MAINTENANCE_ROUTE,
  BULLET_TANK_HISTORY_REPORT_ROUTE,
  BULLET_TANK_INVENTORY_ROUTE,
  BULLET_TANK_MAINTENANCE_ROUTE,
  CASH_ADVANCE_OUTLET_ROUTE,
  CASH_ADVANCE_REPORT_ROUTE,
  CATEGORY_MAINTENANCE_ROUTE,
  CUSTOMER_ENTRY_OUTLET_ROUTE,
  CUSTOMER_HISTORY_REPORT_ROUTE,
  CUSTOMER_LIST_MAINTENANCE_ROUTE,
  EXPENSE_REPORT_ROUTE,
  INCOME_STATEMENT_REPORT_ROUTE,
  INCOMING_INVENTORY_ROUTE,
  OUTLET_HISTORY_REPORT_ROUTE,
  OUTLET_MAINTENANCE_ROUTE,
  PRODUCT_MAINTENANCE_ROUTE,
  PURCHASE_ORDER_OUTLET_ROUTE,
  SALES_ENTRY_ROUTE,
  SALES_HISTORY_REPORT_ROUTE,
  SALES_ORDER_ROUTE,
  TIME_IN_ROUTE,
  TIME_KEEPING_REPORT_ROUTE,
  TIME_KEEPING_ROUTE,
  TIME_OUT_ROUTE,
  USER_MANAGEMENT_ROUTE,
} from '../router/routes';

const salesOrderMenuNavigation = {
  title: 'Sales Order',
  icon: <TfiPencilAlt className="me-2" size={20} />,
  path: SALES_ORDER_ROUTE,
};

const salesEntryMenuNavigation = {
  title: 'Sales Entry',
  icon: <MdOutlinePointOfSale className="me-2" size={20} />,
  path: SALES_ENTRY_ROUTE,
};

const userMenuNavigation = {
  title: 'User Management',
  icon: <FaUsers className="me-2" size={20} />,
  path: USER_MANAGEMENT_ROUTE,
};

const reportSubMenuNavigation = {
  id: '1',
  title: 'Reports',
  icon: <TbReportAnalytics className="me-2" size={20} />,
  items: [
    {
      title: 'Bullet Tank history',
      icon: <BiCylinder className="me-2" size={20} />,
      path: BULLET_TANK_HISTORY_REPORT_ROUTE,
    },
    {
      title: 'Sales history',
      icon: <TbReportMoney className="me-2" size={20} />,
      path: SALES_HISTORY_REPORT_ROUTE,
    },
    {
      title: 'Customer history',
      icon: <GrDocumentUser className="me-2" size={20} />,
      path: CUSTOMER_HISTORY_REPORT_ROUTE,
    },
    {
      title: 'Cash Advance reports',
      icon: <HiOutlineCash className="me-2" size={20} />,
      path: CASH_ADVANCE_REPORT_ROUTE,
    },
    {
      title: 'Income statement',
      icon: <GiCash className="me-2" size={20} />,
      path: INCOME_STATEMENT_REPORT_ROUTE,
    },
    {
      title: 'Expense report',
      icon: <GiExpense className="me-2" size={20} />,
      path: EXPENSE_REPORT_ROUTE,
    },
    {
      title: 'Outlet history',
      icon: <BiStore className="me-2" size={20} />,
      path: OUTLET_HISTORY_REPORT_ROUTE,
    },
    {
      title: 'Time Keeping reports',
      icon: <AiOutlineFieldTime className="me-2" size={20} />,
      path: TIME_KEEPING_REPORT_ROUTE,
    },
  ],
};

const maintenanceSubMenuNavigation = {
  id: '2',
  title: 'Maintenance',
  icon: <GrVmMaintenance className="me-2" size={20} />,
  items: [
    {
      title: 'Bullet Tanks',
      icon: <BiCylinder className="me-2" size={20} />,
      path: BULLET_TANK_MAINTENANCE_ROUTE,
    },
    {
      title: 'Categories',
      icon: <BiCategory className="me-2" size={20} />,
      path: CATEGORY_MAINTENANCE_ROUTE,
    },
    {
      title: 'Products',
      icon: <BiGasPump className="me-2" size={20} />,
      path: PRODUCT_MAINTENANCE_ROUTE,
    },
    {
      title: 'Customer List',
      icon: <HiOutlineUsers className="me-2" size={20} />,
      path: CUSTOMER_LIST_MAINTENANCE_ROUTE,
    },
    {
      title: 'Access Level',
      icon: <SiOpslevel className="me-2" size={20} />,
      path: ACCESS_LEVEL_MAINTENANCE_ROUTE,
    },
    {
      title: 'Outlets',
      icon: <BiStore className="me-2" size={20} />,
      path: OUTLET_MAINTENANCE_ROUTE,
    },
    {
      title: 'Branches',
      icon: <BiGitBranch className="me-2" size={20} />,
      path: BRANCH_MAINTENANCE_ROUTE,
    },
  ],
};

const outletSubMenuNavigation = {
  id: '3',
  title: 'Outlet',
  icon: <TbBuildingStore className="me-2" size={20} />,
  items: [
    {
      title: 'Purchase Order',
      icon: <HiOutlinePencilAlt className="me-2" size={20} />,
      path: PURCHASE_ORDER_OUTLET_ROUTE,
    },
    {
      title: 'Cash Advance Request',
      icon: <BsCashCoin className="me-2" size={20} />,
      path: CASH_ADVANCE_OUTLET_ROUTE,
    },
    {
      title: 'Customer Info Entry',
      icon: <GrDocumentUser className="me-2" size={20} />,
      path: CUSTOMER_ENTRY_OUTLET_ROUTE,
    },
  ],
};

const inventorySubMenuNavigation = {
  id: '4',
  title: 'Inventory',
  icon: <MdOutlineInventory2 className="me-2" size={20} />,
  items: [
    {
      title: 'Bullet Tank',
      icon: <BiCylinder className="me-2" size={20} />,
      path: BULLET_TANK_INVENTORY_ROUTE,
    },
    {
      title: 'Incoming inventory',
      icon: <AiOutlineStock className="me-2" size={20} />,
      path: INCOMING_INVENTORY_ROUTE,
    },
  ],
};

const timekeepingMenuNavigation = {
  id: '5',
  title: 'Time Keeping',
  icon: <BiTimer className="me-2" size={20} />,
  items: [
    {
      title: 'Time In',
      icon: <BiTimer className="me-2" size={20} />,
      path: TIME_IN_ROUTE,
    },
    {
      title: 'Time Out',
      icon: <BiTimer className="me-2" size={20} />,
      path: TIME_OUT_ROUTE,
    },
  ],
};

export {
  salesOrderMenuNavigation,
  salesEntryMenuNavigation,
  userMenuNavigation,
  timekeepingMenuNavigation,
  inventorySubMenuNavigation,
  reportSubMenuNavigation,
  maintenanceSubMenuNavigation,
  outletSubMenuNavigation,
};
