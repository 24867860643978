import React, { useState } from 'react';
import { Col, Modal, Row } from 'react-bootstrap';
import './Loading.css';
import { useEffect } from 'react';

export default function LoadingModal({ isModalOpen }) {
  const [isOpen, setIsOpen] = useState(false);
  const [isLoadingDone, setIsLoadingDone] = useState(false);

  useEffect(() => {
    if (!isModalOpen) {
      setIsLoadingDone(true);
      setTimeout(() => {
        setIsOpen(false);
        setIsLoadingDone(false);
      }, 1000);
    } else {
      setIsOpen(isModalOpen);
      setIsLoadingDone(false);
    }
  }, [isModalOpen]);

  return (
    <Modal show={isOpen} backdrop="static" keyboard="false" centered>
      <Modal.Body>
        {!isLoadingDone && <div className="mx-auto loading"></div>}
        {isLoadingDone && (
          <Row>
            <Col className="text-center">
              <h2>Completed... Thank you!</h2>
            </Col>
          </Row>
        )}
      </Modal.Body>
    </Modal>
  );
}
