import { CustomerDefault } from '../data/_index';

export const getCustomerFields = (
  isRequesting,
  customerState = CustomerDefault,
  setCustomerState
) => {
  return [
    {
      type: 'name',
      placeholder: 'Juan',
      label: 'First name:',
      autofocus: true,
      required: true,
      disabled: isRequesting ? true : false,
      value: customerState.firstName,
      setValue: (e) => {
        const value = e.target.value;
        setCustomerState({ ...customerState, firstName: value });
      },
    },
    {
      type: 'name',
      placeholder: 'Ponce',
      label: 'Middle name:',
      required: true,
      disabled: isRequesting ? true : false,
      value: customerState.middleName,
      setValue: (e) => {
        const value = e.target.value;
        setCustomerState({ ...customerState, middleName: value });
      },
    },
    {
      type: 'name',
      placeholder: 'Dela Cruz',
      label: 'Last name:',
      required: true,
      disabled: isRequesting ? true : false,
      value: customerState.lastName,
      setValue: (e) => {
        const value = e.target.value;
        setCustomerState({ ...customerState, lastName: value });
      },
    },
    {
      type: 'textarea',
      placeholder: 'Address',
      label: 'Address:',
      required: true,
      disabled: isRequesting ? true : false,
      value: customerState.address,
      setValue: (e) => {
        const value = e.target.value;
        setCustomerState({ ...customerState, address: value });
      },
    },
    {
      type: 'mobile-number',
      placeholder: '9XXXXXXXXX',
      label: 'Mobile number:',
      required: true,
      disabled: isRequesting ? true : false,
      value: customerState.mobileNumber,
      setValue: (e) => {
        const value = e.target.value;
        if (value.length !== 11) {
          setCustomerState({ ...customerState, mobileNumber: value });
        }
      },
    },
    {
      type: 'telephone',
      placeholder: '0XXXXXXXX',
      label: 'Landline number:',
      required: true,
      disabled: isRequesting ? true : false,
      value: customerState.telNumber,
      setValue: (e) => {
        const value = e.target.value;
        setCustomerState({ ...customerState, telNumber: value });
      },
    },
    {
      type: 'dropdown',
      dropdownOptions: [
        { id: 'male', description: 'Male' },
        { id: 'female', description: 'Female' },
        { id: 'others', description: 'Others' },
      ],
      label: 'Gender:',
      required: true,
      disabled: isRequesting ? true : false,
      value: customerState.gender,
      setValue: (e) => {
        const value = e.target.value;
        setCustomerState({ ...customerState, gender: value });
      },
    },
    {
      type: 'dropdown',
      dropdownOptions: [
        { id: 'single', description: 'Single' },
        { id: 'married', description: 'Married' },
        { id: 'divorced', description: 'Divorced' },
        { id: 'separated', description: 'Separated' },
        { id: 'widowed', description: 'Widowed' },
      ],
      label: 'Marital Status:',
      required: true,
      disabled: isRequesting ? true : false,
      value: customerState.maritalStatus,
      setValue: (e) => {
        const value = e.target.value;
        setCustomerState({ ...customerState, maritalStatus: value });
      },
    },
  ];
};
