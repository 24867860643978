import React from "react";
import { Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Navigation.css";

export default function SubMenu({ nav, currentLocation }) {
  return (
    <Accordion flush className="border-bottom">
      <Accordion.Item eventKey={nav.id}>
        <Accordion.Header>
          {nav.icon} {nav.title}
        </Accordion.Header>
        <Accordion.Body className="p-0 m-0 ms-3">
          {nav.items.map((item, index) => (
            <Link
              key={index}
              to={`${item.path}`}
              className={`border-0 border-bottom list-group-item list-group-item-action py-2 ripple ${
                currentLocation.pathname === `${item.path}` ? "active" : ""
              }`}
            >
              {item.icon}
              <span>{item.title}</span>
            </Link>
          ))}
        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}
