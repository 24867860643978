import React from 'react';
import { Button } from 'react-bootstrap';

export const userManagementTableHeader = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    sorter: (a, b) => a.id - b.id,
    fixed: 'left',
  },
  {
    title: 'Name',
    dataIndex: 'field1',
    key: 'field1',
    sorter: (a, b) => a.field1.localeCompare(b.field1),
  },
  {
    title: 'Username',
    dataIndex: 'field2',
    key: 'field2',
    sorter: (a, b) => a.field2.localeCompare(b.field2),
  },
  {
    title: 'Access Level',
    dataIndex: 'field3',
    key: 'field3',
    sorter: (a, b) => a.field3.localeCompare(b.field3),
  },
  // {
  //   title: 'Actions',
  //   key: 'action',
  //   render: (text, record) => (
  //     <>
  //       <Button
  //         variant="success"
  //         onClick={() => record.onEdit(record.id)}
  //         className="me-2 my-2"
  //       >
  //         Edit
  //       </Button>
  //       <Button
  //         variant="danger"
  //         onClick={() => record.onDelete(record.id)}
  //         className="my-2"
  //       >
  //         Delete
  //       </Button>
  //     </>
  //   ),
  // },
];
