import React, { useContext, useEffect, useState } from 'react';
import { Card, Col, Container, Row } from 'react-bootstrap';
import { GlobalContext } from '../../App';
import { UserContext } from '../../utils/context/UserContext';
import { API_BOOLEAN_TRUE } from '../../utils/api/APIConstants';

export default function ContainerSelection({ customer, onSelectContainer }) {
  const { setIsToastOpen, setToast } = useContext(GlobalContext);
  const {
    profile,
    productsList,
    loadAllProducts,
    loadProductsBySearch,
    loadProductsByCategoryId,
    isErrorAlertShown,
    setIsErrorAlertShown,
    errorMessage,
    setErrorMessage,
  } = useContext(UserContext);

  const [isLoading, setIsLoading] = useState(false);
  const [localProductsList, setLocalProductsList] = useState([]);

  const handleInitialState = async () => {
    setIsLoading(true);
    await loadAllProducts();
    setLocalProductsList(productsList);
    setIsLoading(false);
  };

  useEffect(() => {
    if (localProductsList.length === 0) {
      handleInitialState();
    }
  }, [productsList]);

  return (
    <>
      <Row>
        <Col sm={{ span: 6, offset: 3 }} className="text-center">
          <h2>Hi, {customer.firstName}. Please select your tank.</h2>
          <span className="text-muted">
            {isLoading ? 'Loading...' : 'Please select your tank container.'}
          </span>
        </Col>
      </Row>
      <Container>
        <Row className="mt-3">
          {localProductsList
            .filter(
              (product) =>
                product.isDeleted !== API_BOOLEAN_TRUE &&
                product.isTank !== API_BOOLEAN_TRUE
            )
            .map((product, index) => (
              <Col key={index} className="text-center">
                <Card
                  style={{ cursor: 'pointer', width: '250px' }}
                  onClick={() => onSelectContainer(product)}
                >
                  <Card.Img
                    variant="top"
                    src={
                      product.image === ''
                        ? 'https://img.tradeford.com/pimages/l/2/1025722.jpg'
                        : product.image
                    }
                  />
                  <Card.Body>
                    <Card.Title className="text-center">
                      <strong>{product.name}</strong>
                    </Card.Title>
                    <Card.Text>
                      Description: <strong>{product.description}</strong>
                    </Card.Text>
                    <Card.Text>
                      Category: <strong>{product.category}</strong>
                    </Card.Text>
                    <Card.Text>
                      KG capacity: <strong>{product.quantity}</strong>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
        </Row>
      </Container>
    </>
  );
}
