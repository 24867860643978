import React, { useContext, useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { GlobalContext } from '../../App';
import {
  ConfirmationModal,
  CustomPagination,
  CustomTable,
} from '../../components';
import {
  API_BOOLEAN_TRUE,
  getAPIResponseMessage,
} from '../../utils/api/APIConstants';
import {
  deactivateUser,
  getUserById,
  register,
} from '../../utils/api/UsersAPI';
import { TOAST_USER_UPDATE } from '../../utils/constants';
import { UserContext } from '../../utils/context/UserContext';
import { UserDefault } from '../../utils/data/_index';
import UserModal from './UserModal';
import { Table } from 'antd';
import { userManagementTableHeader } from '../../utils/TableData/UserManagementTable';

export default function UserManagement() {
  const { setIsToastOpen, setToast } = useContext(GlobalContext);
  const {
    profile,
    userList,
    loadUserList,
    accessLevelList,
    loadAccessLevel,
    isErrorAlertShown,
    setIsErrorAlertShown,
    errorMessage,
    setErrorMessage,
  } = useContext(UserContext);

  const [isLoading, setIsLoading] = useState(false);
  const [isRequesting, setIsRequesting] = useState(false);

  const [isConfirmationModalShown, setIsConfirmationModalShown] =
    useState(false);

  const [localUserList, setLocalUserList] = useState([{ UserDefault }]);
  const [isUserModalShown, setIsUserModalShown] = useState(false);
  const [currentUserState, setCurrentUserState] = useState(UserDefault);
  const [isNewEntry, setIsNewEntry] = useState(false);

  const [accessLevelOptions, setAccessLevelOptions] = useState([]);

  const tableContents = localUserList
    .filter((user) => user.isDeleted !== API_BOOLEAN_TRUE)
    .filter((user) => user.id !== profile.id)
    .map((user) => ({
      id: user.id,
      field1: `${user.firstName} ${user.lastName}`,
      field2: user.username,
      field3: user.accessDescription,
      onEdit: async (id) => {
        setIsNewEntry(false);
        const user = await getUserById(profile.id, id);
        if (user.isSuccessful) {
          setCurrentUserState(user.data);
          setIsUserModalShown(true);
        } else {
          setErrorMessage(getAPIResponseMessage(user.data));
          setIsErrorAlertShown(true);
        }
      },
      onDelete: (id) => {
        const userListCopy = [...localUserList];
        const user = userListCopy.filter((user) => user.id === id)[0];
        setCurrentUserState(user);
        setIsConfirmationModalShown(true);
      },
    }));

  const handleSaveUser = async (e) => {
    e.preventDefault();
    setIsRequesting(true);
    const data = await register(profile.id, currentUserState);
    if (data.isSuccessful) {
      setIsUserModalShown(false);
      setToast({
        title: TOAST_USER_UPDATE,
        content: getAPIResponseMessage(data.data),
      });
      setIsToastOpen(true);
      await loadData();
    } else {
      setErrorMessage(getAPIResponseMessage(data.data));
      setIsErrorAlertShown(true);
    }
    setIsRequesting(false);
  };

  const handleDeactivateUser = async () => {
    setIsLoading(true);
    const data = await deactivateUser(profile.id, {
      userId: currentUserState.id,
      updatedBy: profile.id,
    });
    if (data.isSuccessful) {
      setIsConfirmationModalShown(false);
      setToast({
        title: TOAST_USER_UPDATE,
        content: getAPIResponseMessage(data.data),
      });
      setIsToastOpen(true);
      await loadData();
    } else {
      setErrorMessage(getAPIResponseMessage(data.data));
      setIsErrorAlertShown(true);
      setIsLoading(false);
    }
  };

  const setAllDefault = () => {
    setIsNewEntry(true);
    setCurrentUserState(UserDefault);
    setIsErrorAlertShown(false);
  };

  const loadData = async () => {
    setIsLoading(true);
    await loadUserList();
    await loadAccessLevel();
    setIsLoading(false);
  };

  useEffect(() => {
    setAccessLevelOptions(
      [...accessLevelList].filter((level) => {
        if (profile.accessLevel === '1') {
          return level.accessCode !== '';
        } else {
          return level.accessCode !== '1';
        }
      })
    );
  }, [accessLevelList]);

  useEffect(() => {
    setLocalUserList(userList);
  }, [userList]);

  useEffect(() => {
    loadData();
  }, []);

  return (
    <Container className="pt-3">
      <Row>
        <Col>
          <h2>User Management</h2>
        </Col>
        <Col className="text-end">
          <Button
            variant="primary"
            disabled={isLoading}
            onClick={() => {
              setAllDefault();
              setIsUserModalShown(true);
            }}
          >
            Register User
          </Button>
        </Col>
      </Row>
      <Table
        columns={userManagementTableHeader}
        dataSource={tableContents}
        size="small"
        bordered="true"
        // scroll={{ x: true }}
        loading={isLoading}
      />
      <UserModal
        isUserModalShown={isUserModalShown}
        setIsUserModalShown={setIsUserModalShown}
        isNewEntry={isNewEntry}
        isRequesting={isRequesting}
        isErrorAlertShown={isErrorAlertShown}
        setIsErrorAlertShown={setIsErrorAlertShown}
        currentUserState={currentUserState}
        setCurrentUserState={setCurrentUserState}
        handleSubmit={handleSaveUser}
        accessLevelOptions={accessLevelOptions}
        profile={profile}
        errorMessage={errorMessage}
      />
      <ConfirmationModal
        isModalOpen={isConfirmationModalShown}
        setIsModalOpen={setIsConfirmationModalShown}
        handleConfirm={handleDeactivateUser}
        variant={'danger'}
        description={`Confirm DELETE for account: ${currentUserState.username}`}
      />
    </Container>
  );
}
