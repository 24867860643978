import React, { useContext, useEffect, useState } from 'react';
import { Alert, Button, Form, InputGroup, Modal } from 'react-bootstrap';
import { getAPIResponseMessage } from '../../utils/api/APIConstants';
import { changePassword } from '../../utils/api/UsersAPI';
import { GrFormViewHide, GrFormView } from 'react-icons/gr';
import { GlobalContext } from '../../App';
import { TOAST_CHANGE_PASSWORD } from '../../utils/constants';

export default function ChangePasswordModal({
  isModalOpen,
  setIsModalOpen,
  profile,
}) {
  const { setIsToastOpen, setToast } = useContext(GlobalContext);

  const [isLoading, setIsLoading] = useState(false);
  const [isErrorMessageOpen, setIsErrorMessageOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [isOldPasswordHidden, setIsOldPasswordHidden] = useState(true);
  const [isNewPasswordHidden, setIsNewPasswordHidden] = useState(true);
  const [isConfirmPasswordHidden, setIsConfirmPasswordHidden] = useState(true);

  const [userPasswordState, setUserPasswordState] = useState({
    id: '',
    oldPassword: '',
    newPassword: '',
    confirmPassword: '',
    updatedBy: '',
  });

  const handleChangePassword = async () => {
    setIsLoading(true);
    if (userPasswordState.newPassword === userPasswordState.oldPassword) {
      setIsErrorMessageOpen(true);
      setErrorMessage('Password matched. No need to change.');
    } else {
      const data = await changePassword(profile.id, userPasswordState);
      if (data.isSuccessful) {
        setIsModalOpen(false);
        setToast({
          title: TOAST_CHANGE_PASSWORD,
          content: getAPIResponseMessage(data.data),
        });
        setIsToastOpen(true);
      } else {
        setErrorMessage(getAPIResponseMessage(data.data));
        setIsErrorMessageOpen(true);
      }
    }
    setIsLoading(false);
  };

  useEffect(() => {
    setUserPasswordState({
      ...userPasswordState,
      id: profile.id,
      updatedBy: profile.id,
      newPassword: '',
      oldPassword: '',
      confirmPassword: '',
    });
  }, [isModalOpen]);

  return (
    <Modal
      show={isModalOpen}
      onHide={() => setIsModalOpen(false)}
      backdrop="static"
      keyboard="false"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Change Password</Modal.Title>
      </Modal.Header>
      <Alert
        variant="danger"
        show={isErrorMessageOpen}
        onClose={() => setIsErrorMessageOpen(false)}
        dismissible
        className="my-0"
      >
        {errorMessage}
      </Alert>
      <Modal.Body>
        <Form.Group className="mb-3" controlId="formOldPassword">
          <Form.Label>Old Password</Form.Label>

          <InputGroup className="mb-3">
            <Form.Control
              type={isOldPasswordHidden ? 'password' : 'text'}
              placeholder="Old Password"
              value={userPasswordState.oldPassword}
              required={true}
              disabled={isLoading}
              onChange={(e) => {
                const value = e.target.value;
                setUserPasswordState({
                  ...userPasswordState,
                  oldPassword: value,
                });
              }}
            />

            <InputGroup.Text>
              {isOldPasswordHidden ? (
                <GrFormViewHide
                  size={24}
                  cursor="pointer"
                  onClick={() => setIsOldPasswordHidden(!isOldPasswordHidden)}
                />
              ) : (
                <GrFormView
                  size={24}
                  cursor="pointer"
                  onClick={() => setIsOldPasswordHidden(!isOldPasswordHidden)}
                />
              )}
            </InputGroup.Text>
          </InputGroup>
        </Form.Group>
        <Form.Group className="mb-3" controlId="formNewPassword">
          <Form.Label>New Password</Form.Label>

          <InputGroup className="mb-3">
            <Form.Control
              type={isNewPasswordHidden ? 'password' : 'text'}
              placeholder="New Password"
              value={userPasswordState.newPassword}
              required={true}
              disabled={isLoading}
              onChange={(e) => {
                const value = e.target.value;
                setUserPasswordState({
                  ...userPasswordState,
                  newPassword: value,
                });
              }}
            />

            <InputGroup.Text>
              {isNewPasswordHidden ? (
                <GrFormViewHide
                  size={24}
                  cursor="pointer"
                  onClick={() => setIsNewPasswordHidden(!isNewPasswordHidden)}
                />
              ) : (
                <GrFormView
                  size={24}
                  cursor="pointer"
                  onClick={() => setIsNewPasswordHidden(!isNewPasswordHidden)}
                />
              )}
            </InputGroup.Text>
          </InputGroup>
        </Form.Group>
        <Form.Group
          className="mb-3"
          controlId="formConfirmPassword"
          hidden={userPasswordState.newPassword === ''}
        >
          <Form.Label>Confirm Password</Form.Label>
          <InputGroup className="mb-3">
            <Form.Control
              type={isConfirmPasswordHidden ? 'password' : 'text'}
              placeholder="Confirm Password"
              value={userPasswordState.confirmPassword}
              required={true}
              disabled={isLoading}
              onChange={(e) => {
                const value = e.target.value;
                setUserPasswordState({
                  ...userPasswordState,
                  confirmPassword: value,
                });
              }}
            />

            <InputGroup.Text>
              {isConfirmPasswordHidden ? (
                <GrFormViewHide
                  size={24}
                  cursor="pointer"
                  onClick={() =>
                    setIsConfirmPasswordHidden(!isConfirmPasswordHidden)
                  }
                />
              ) : (
                <GrFormView
                  size={24}
                  cursor="pointer"
                  onClick={() =>
                    setIsConfirmPasswordHidden(!isConfirmPasswordHidden)
                  }
                />
              )}
            </InputGroup.Text>
          </InputGroup>
        </Form.Group>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setIsModalOpen(false)}>
          Close
        </Button>
        <Button
          variant="success"
          onClick={handleChangePassword}
          disabled={isLoading}
        >
          {isLoading ? 'Updating password...' : 'Update password'}
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
