import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Alert, Button, FloatingLabel, Form } from 'react-bootstrap';

import './login.css';
import { GlobalContext } from '../../App';
import { login } from '../../utils/api/UsersAPI';
import { UserContext } from '../../utils/context/UserContext';
import { saveToken, saveValidUntil } from '../../utils/token';
import { getAPIResponseMessage } from '../../utils/api/APIConstants';

export default function Login() {
  const { setIsLoggedIn } = useContext(GlobalContext);
  const { setActiveProfile } = useContext(UserContext);
  const navigate = useNavigate();

  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isErrorAlertShown, setIsErrorAlertShown] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const response = await login(username, password);
    if (response.isSuccessful) {
      saveToken(response.authToken.token);
      saveValidUntil(response.authToken.validUntil);
      setActiveProfile(response.data);
      navigate('/');
      setIsLoggedIn(true);
    } else {
      setErrorMessage(getAPIResponseMessage(response.data));
      setIsErrorAlertShown(true);
    }

    setIsLoading(false);
  };
  return (
    <div className="Auth-form-container">
      <Form className="Auth-form p-5" onSubmit={handleSubmit}>
        <h3 className="text-white text-center">Sign In</h3>
        <Form.Group className="mb-3" controlId="formBasicUserName">
          <FloatingLabel
            controlId="floatingUsernameInput"
            label="Username"
            className="text-muted"
          >
            <Form.Control
              type="text"
              required={true}
              placeholder="Please enter your username."
              onChange={(e) => setUsername(e.target.value)}
              value={username}
              disabled={isLoading ? true : false}
            />
          </FloatingLabel>
          <Form.Text className="text-muted">
            Please do not share your login credentials to anyone else.
          </Form.Text>
        </Form.Group>

        <Form.Group className="mb-3" controlId="formBasicPassword">
          <FloatingLabel
            controlId="floatingPasswordInput"
            label="Password"
            className="text-muted"
          >
            <Form.Control
              type="password"
              placeholder="Password"
              required={true}
              onChange={(e) => setPassword(e.target.value)}
              value={password}
              disabled={isLoading ? true : false}
            />
          </FloatingLabel>
        </Form.Group>
        <Alert
          variant="danger"
          show={isErrorAlertShown}
          onClose={() => setIsErrorAlertShown(false)}
          dismissible
          className="my-0"
        >
          {errorMessage}
        </Alert>
        <Button
          variant="outline-primary"
          type="submit"
          className="btn-block w-100 mt-3 py-2"
          disabled={isLoading ? true : false}
        >
          Submit
        </Button>
      </Form>
    </div>
  );
}
