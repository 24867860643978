const BASE_ENDPOINT = 'https://hermitapi.xgitcorp.com';

const PER_USER_ENDPOINT = '/user';
const USERS_ENDPOINT = '/users';
const LOGIN = '/login';
const REGISTER = '/register';
const DEACTIVATE_USER = '/deactivate';
const CHANGE_PASSWORD = '/change-password';
const CUSTOMERS = '/customers';
const CATEGORIES = '/categories';
const PRODUCTS = '/products';
const BRANCHES = '/branches';

const ACCESS_LEVEL = '/access-level';

export const IMAGE_URL_BASE_PATH = 'https://gas-sis.xgitcorp.com/';

// USERS API
export const LOGIN_ENDPOINT = BASE_ENDPOINT + USERS_ENDPOINT + LOGIN;
export const REGISTER_ENDPOINT = BASE_ENDPOINT + USERS_ENDPOINT + REGISTER;
export const GET_USERS_BY_BRANCH_ENDPOINT = BASE_ENDPOINT + USERS_ENDPOINT;
export const DEACTIVATE_USER_ENDPOINT =
  BASE_ENDPOINT + USERS_ENDPOINT + DEACTIVATE_USER;
export const GET_USER_BY_ID_ENDPOINT = BASE_ENDPOINT + PER_USER_ENDPOINT;
export const USER_CHANGE_PASSWORD_ENDPOINT =
  BASE_ENDPOINT + USERS_ENDPOINT + CHANGE_PASSWORD;

// ACCESS LEVEL API
export const ACCESS_LEVEL_ENDPOINT = BASE_ENDPOINT + ACCESS_LEVEL;

// CUSTOMERS API
export const CUSTOMERS_ENDPOINT = BASE_ENDPOINT + CUSTOMERS;

// CATEGORIES API
export const CATEGORIES_ENDPOINT = BASE_ENDPOINT + CATEGORIES;

// PRODUCTS API
export const PRODUCTS_ENDPOINT = BASE_ENDPOINT + PRODUCTS;

// BRANCHES API
export const BRANCHES_ENDPOINT = BASE_ENDPOINT + BRANCHES;

// API RESPONSE
export const API_CALL_ERROR = 'api_call_error';
export const API_BOOLEAN_TRUE = '1';

// Failed returns - TOKEN
const INVALID_TOKEN = 'invalid_token';
const EXPIRED_TOKEN = 'expired_token';
const NO_TOKEN = 'no_token';

// Failed returns - LOGIN
const WRONG_CREDENTIALS = 'wrong_credentials';

// Failed returns - USERS
const USER_EXISTS = 'user_exists';
const USER_DOES_NOT_EXISTS = 'user_does_not_exist';
const NO_USER_FOUND = 'no_user_found';

// Failed returns - RECORDS
const RECORD_EXISTS = 'record_already_exists';
const NO_RECORD_FOUND = 'no_record_found';

// Failed return - TRANSACTION
const NO_TRANSACTION_FOUND = 'no_transaction_found';

// Failed returns - GENERAL
const SOMETHING_WENT_WRONG = 'something_went_wrong';
const INVALID_REQUEST = 'invalid_request';

// Passed returns - USERS
const USER_SUCCESS_ADD = 'user_success_add';
const USER_SUCCESS_DEACTIVATE = 'user_deactivated';
const USER_SUCCESS_UPDATE = 'user_updated';
const USER_PASSWORD_CHANGED = 'password_changed';

// PASSED returns - RECORDS
const RECORD_SUCCESS_ADD = 'add_record_success';
const RECORD_SUCCESS_UPDATE = 'update_record_success';

// Success response - TRANSACTION
const POSTED_SUCCESS = 'transaction_posted_success';

export const getAPIResponseMessage = (apiReturn) => {
  switch (apiReturn) {
    case INVALID_TOKEN:
      return 'Invalid Authorization token.';
    case EXPIRED_TOKEN:
      return 'Expired Authorization token.';
    case NO_TOKEN:
      return 'No provided token.';
    case WRONG_CREDENTIALS:
      return 'Wrong credentials.';
    case USER_EXISTS:
      return 'User already exists.';
    case USER_DOES_NOT_EXISTS:
      return 'User does not exists.';
    case NO_USER_FOUND:
      return 'No user found.';
    case SOMETHING_WENT_WRONG:
      return 'Something went wrong.';
    case INVALID_REQUEST:
      return 'Invalid request. Please fill up all required field.';
    case NO_TRANSACTION_FOUND:
      return 'No Transaction found.';
    case RECORD_EXISTS:
      return 'Record already exists.';
    case NO_RECORD_FOUND:
      return 'No record found.';

    // HAPPY PATHS
    case USER_SUCCESS_ADD:
      return 'User added.';
    case USER_SUCCESS_DEACTIVATE:
      return 'User deactivated.';
    case USER_SUCCESS_UPDATE:
      return 'User updated.';
    case USER_PASSWORD_CHANGED:
      return 'Password updated.';
    case RECORD_SUCCESS_ADD:
      return 'Record added.';
    case RECORD_SUCCESS_UPDATE:
      return 'Record updated.';
    default:
      break;
  }
};
