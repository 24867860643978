import React, { useContext, useEffect, useState } from 'react';
import { Link, redirect, useLocation } from 'react-router-dom';
import { NavDropdown } from 'react-bootstrap';
import './Navigation.css';
import SubMenu from './SubMenu';
import Menu from './Menu';
import {
  inventorySubMenuNavigation,
  maintenanceSubMenuNavigation,
  outletSubMenuNavigation,
  reportSubMenuNavigation,
  salesEntryMenuNavigation,
  salesOrderMenuNavigation,
  timekeepingMenuNavigation,
  userMenuNavigation,
} from '../../utils/data/navigation';
import { RiLockPasswordLine } from 'react-icons/ri';
import { HiOutlineLogout } from 'react-icons/hi';
import { AiOutlineBars } from 'react-icons/ai';
import { HOME_ROUTE } from '../../utils/router/routes';
import { BiGasPump } from 'react-icons/bi';
import { UserContext } from '../../utils/context/UserContext';
import { GlobalContext } from '../../App';
import { removeToken, removeValidUntil } from '../../utils/token';
import ChangePasswordModal from '../ChangePasswordModal/ChangePasswordModal';

export default function Navigation({ children }) {
  const currentLocation = useLocation();

  const { profile, getActiveProfile, setActiveProfile } =
    useContext(UserContext);
  const { setIsLoggedIn } = useContext(GlobalContext);

  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] =
    useState(false);

  useEffect(() => {
    if (profile.username === '') {
      getActiveProfile();
    }
  }, []);

  return (
    <>
      <header>
        {/* SIDE-BAR NAVIGATION */}
        <nav id="sidebarMenu" className="collapse d-lg-block sidebar bg-white">
          <div className="position-sticky">
            <div className="list-group list-group-flush mx-3 mt-4">
              <Menu
                info={salesOrderMenuNavigation}
                currentLocation={currentLocation}
              />
              <Menu
                info={salesEntryMenuNavigation}
                currentLocation={currentLocation}
              />
              <Menu
                info={userMenuNavigation}
                currentLocation={currentLocation}
              />
              <SubMenu
                nav={inventorySubMenuNavigation}
                currentLocation={currentLocation}
              />
              <SubMenu
                nav={reportSubMenuNavigation}
                currentLocation={currentLocation}
              />
              <SubMenu
                nav={maintenanceSubMenuNavigation}
                currentLocation={currentLocation}
              />
              <SubMenu
                nav={outletSubMenuNavigation}
                currentLocation={currentLocation}
              />
              <SubMenu
                nav={timekeepingMenuNavigation}
                currentLocation={currentLocation}
              />
            </div>
          </div>
        </nav>

        {/* TOP-BAR NAVIGATION */}
        <div
          id="main-navbar"
          className="navbar navbar-expand-lg navbar-dark bg-primary fixed-top"
        >
          <div className="container-fluid">
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#sidebarMenu"
              aria-controls="sidebarMenu"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <AiOutlineBars className="me-2" size={20} />
            </button>

            <Link to={HOME_ROUTE} className="navbar-brand">
              <BiGasPump className="ms-3 me-1" size={25} />
              <span className="company-name">LPG - Sales Inventory System</span>
            </Link>

            <NavDropdown
              title={`Hi, ${profile.username}`}
              id="basic-nav-dropdown"
              className="text-white mx-3"
            >
              <NavDropdown.Item
                to={HOME_ROUTE}
                onClick={() => {
                  setIsChangePasswordModalOpen(true);
                }}
                className={'list-group-item list-group-item-action p-2 ripple'}
              >
                <RiLockPasswordLine className="me-2" size={20} />
                <span>Change Password</span>
              </NavDropdown.Item>
              <NavDropdown.Item
                to={HOME_ROUTE}
                onClick={() => {
                  setActiveProfile({});
                  removeToken();
                  removeValidUntil();
                  setIsLoggedIn(false);
                  redirect(HOME_ROUTE);
                }}
                className={'list-group-item list-group-item-action p-2 ripple'}
              >
                <HiOutlineLogout className="me-2" size={20} />
                <span>Sign Out</span>
              </NavDropdown.Item>
            </NavDropdown>
          </div>
        </div>
      </header>
      <main style={{ marginTop: '58px' }}>
        <div>
          {children}
          <ChangePasswordModal
            isModalOpen={isChangePasswordModalOpen}
            setIsModalOpen={setIsChangePasswordModalOpen}
            profile={profile}
          />
        </div>
      </main>
    </>
  );
}
