import React, { useState } from 'react';
import {
  Alert,
  Button,
  Col,
  Form,
  InputGroup,
  Modal,
  Row,
} from 'react-bootstrap';
import { ProductDefault } from '../../utils/data/_index';

export default function RefillModal({
  isModalOpen,
  setIsModalOpen,
  container = ProductDefault,
  handleAddToCart,
}) {
  const [refillValue, setRefillValue] = useState('');
  const [isErrorAlertShown, setIsErrorAlertShown] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  return (
    <Modal
      show={isModalOpen}
      onHide={() => setIsModalOpen(false)}
      backdrop="static"
      keyboard="false"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title>Refill {container.name}.</Modal.Title>
      </Modal.Header>
      <Alert
        variant="danger"
        show={isErrorAlertShown}
        onClose={setIsErrorAlertShown}
        dismissible
        className="my-0"
      >
        {errorMessage}
      </Alert>
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          if (parseInt(refillValue) <= parseInt(container.quantity)) {
            const orderInfo = {
              container: container,
              refillRequest: refillValue,
            };
            handleAddToCart(orderInfo);
            setRefillValue('');
            setIsErrorAlertShown(false);
            setIsModalOpen(false);
          } else {
            setErrorMessage('Requested volume is more than the tank capacity.');
            setIsErrorAlertShown(true);
          }
        }}
      >
        <Modal.Body>
          <Form.Group className="mb-2">
            <Form.Label>
              Please enter how many KG you want to refill your tank.
            </Form.Label>
            <Row>
              <Col>
                <Form.Control
                  type="number"
                  autoFocus={true}
                  onFocus={(e) => e.target.select()}
                  value={refillValue}
                  required={true}
                  onChange={(e) => {
                    const value = e.target.value;
                    setRefillValue(value);
                  }}
                />
              </Col>
              <Col xs={2} className="p-0">
                <Button
                  variant="primary"
                  onClick={() => {
                    setRefillValue(container.quantity);
                  }}
                >
                  Max
                </Button>
              </Col>
            </Row>
            <Form.Text className="text-muted">
              Maximum entry is <strong>{container.quantity}KG</strong>
            </Form.Text>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setIsModalOpen(false)}>
            Close
          </Button>
          <Button variant="primary" type="submit">
            Add to cart
          </Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
}
