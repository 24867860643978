import { createContext, useState } from 'react';
import { getAccessLevels } from '../api/AccessLevelAPI';
import { getUsersByBranch } from '../api/UsersAPI';
import { CURRENT_PROFILE_DETAILS } from '../constants';
import { UserDefault } from '../data/_index';
import { decryptValue, encryptValue } from '../encryption';
import { getCustomerById, getCustomerBySearch } from '../api/CustomersAPI';
import { getCategories } from '../api/CategoriesAPI';
import {
  getProducts,
  getProductsByCategoryId,
  getProductsBySearch,
} from '../api/ProductsAPI';
import { getBranchById, getBranches } from '../api/BranchAPI';

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [isErrorAlertShown, setIsErrorAlertShown] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [profile, setProfile] = useState(UserDefault);

  const [userList, setUserList] = useState([]);
  const [accessLevelList, setAccessLevelList] = useState([]);
  const [customersList, setCustomersList] = useState([]);
  const [categoriesList, setCategoriesList] = useState([]);
  const [productsList, setProductsList] = useState([]);
  const [branchesList, setBranchesList] = useState([]);

  const setActiveProfile = (details) => {
    const encryptedProfile = encryptValue(details);

    localStorage.setItem(CURRENT_PROFILE_DETAILS, encryptedProfile);
    setProfile(details);
  };

  const getActiveProfile = () => {
    const val = localStorage.getItem(CURRENT_PROFILE_DETAILS);
    if (val !== undefined && val !== null) {
      const decryptedProfile = decryptValue(val);
      setProfile(decryptedProfile);
    }
  };

  const loadUserList = async () => {
    const users = await getUsersByBranch(profile.id, profile.branchCode);
    if (users.isSuccessful) {
      setUserList(users.data);
    } else {
      setErrorMessage(users.data);
      setIsErrorAlertShown(true);
    }
  };

  const loadAccessLevel = async () => {
    const levels = await getAccessLevels(profile.id);
    if (levels.isSuccessful) {
      setAccessLevelList(levels.data);
    } else {
      setErrorMessage(levels.data);
      setIsErrorAlertShown(true);
    }
  };

  const loadAllCustomers = async () => {
    const customers = await getCustomerBySearch();
    if (customers.isSuccessful) {
      setCustomersList(customers.data);
    } else {
      setErrorMessage(customers.data);
      setIsErrorAlertShown(true);
    }
  };

  const loadCustomersBySearch = async (search) => {
    const customers = await getCustomerBySearch(search);
    if (customers.isSuccessful) {
      setCustomersList(customers.data);
    } else {
      setErrorMessage(customers.data);
      setIsErrorAlertShown(true);
    }
  };

  const loadCustomerById = async (customerId) => {
    const customers = await getCustomerById(customerId);
    if (customers.isSuccessful) {
      setCustomersList(customers.data);
    } else {
      setErrorMessage(customers.data);
      setIsErrorAlertShown(true);
    }
  };

  const loadAllCategories = async () => {
    const categories = await getCategories(profile.id, profile.branchCode);
    if (categories.isSuccessful) {
      setCategoriesList(categories.data);
    } else {
      setErrorMessage(categories.data);
      setIsErrorAlertShown(true);
    }
  };

  const loadAllProducts = async () => {
    const products = await getProducts();
    if (products.isSuccessful) {
      setProductsList(products.data);
    } else {
      setErrorMessage(products.data);
      setIsErrorAlertShown(true);
    }
  };

  const loadProductsBySearch = async (search) => {
    const products = await getProductsBySearch(search);
    if (products.isSuccessful) {
      setProductsList(products.data);
    } else {
      setErrorMessage(products.data);
      setIsErrorAlertShown(true);
    }
  };

  const loadProductsByCategoryId = async (categoryId) => {
    const products = await getProductsByCategoryId(categoryId);
    if (products.isSuccessful) {
      setProductsList(products.data);
    } else {
      setErrorMessage(products.data);
      setIsErrorAlertShown(true);
    }
  };

  const loadAllBranches = async () => {
    const branches = await getBranches();
    if (branches.isSuccessful) {
      setBranchesList(branches.data);
    } else {
      setErrorMessage(branches.data);
      setIsErrorAlertShown(true);
    }
  };

  const value = {
    isErrorAlertShown,
    setIsErrorAlertShown,
    errorMessage,
    setErrorMessage,
    profile,
    setActiveProfile,
    getActiveProfile,
    userList,
    loadUserList,
    accessLevelList,
    loadAccessLevel,
    customersList,
    loadAllCustomers,
    loadCustomersBySearch,
    loadCustomerById,
    categoriesList,
    loadAllCategories,
    productsList,
    loadAllProducts,
    loadProductsBySearch,
    loadProductsByCategoryId,
    branchesList,
    loadAllBranches,
  };

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};
