import React from 'react';
import { Toast, ToastContainer } from 'react-bootstrap';

export default function CustomToast({ isToastShown, setIsToastShown, toast }) {
  return (
    <ToastContainer className="p-3" position="top-end">
      <Toast
        bg="light"
        delay={5000}
        autohide
        show={isToastShown}
        onClose={() => setIsToastShown(false)}
      >
        <Toast.Header closeButton={true}>
          <strong className="me-auto">{toast.title}</strong>
          <small>
            {new Date().toLocaleString('en-US', { timeZone: 'Asia/Manila' })}
          </small>
        </Toast.Header>
        <Toast.Body>{toast.content}</Toast.Body>
      </Toast>
    </ToastContainer>
  );
}
