export default {
  id: '',
  code: '',
  image: '',
  name: '',
  description: '',
  category: '',
  quantity: '',
  price: '',
  branchCode: '',
  isTank: '',
  isDeleted: '',
  updatedBy: '',
  timestamp: '',
};
