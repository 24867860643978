import CryptoJS from 'crypto-js';

const passKey = 'jchf84OFKyr648f9dkOS4tAEGT36HTYTFfith57vco';

export const encryptValue = (data) => {
  let encryptedData = '';
  try {
    encryptedData = CryptoJS.AES.encrypt(
      JSON.stringify(data),
      passKey
    ).toString();
  } catch (e) {
    console.log('Error occured while encrypting.');
  }

  return encryptedData;
};

export const decryptValue = (data) => {
  let decryptData = '';
  try {
    const bytes = CryptoJS.AES.decrypt(data, passKey);
    decryptData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  } catch (e) {
    console.log('Error occured while decrypting.');
  }

  return decryptData;
};
