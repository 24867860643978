import { Table } from 'antd';
import React, { useState } from 'react';
import { Button, Col, Container, Row } from 'react-bootstrap';
import { temporaryTableHeader } from '../../utils/TableData/Maintenance/TempTableHeader';

export default function ProductsMaintenance() {
  const [isLoading, setIsLoading] = useState(false);
  const [localAccessListData, setLocalAccessListData] = useState([]);

  return (
    <Container className="pt-3">
      <Row>
        <Col>
          <h2>Products Maintenance</h2>
        </Col>
        <Col className="text-end">
          <Button variant="primary" disabled={isLoading} onClick={() => {}}>
            Add Product
          </Button>
        </Col>
      </Row>
      <Table
        columns={temporaryTableHeader}
        dataSource={localAccessListData}
        size="small"
        bordered="true"
        scroll={{ x: true }}
        loading={isLoading}
      />
    </Container>
  );
}
