import React, { useEffect } from 'react';
import { Button, Card, Col, Modal, Row } from 'react-bootstrap';
import { RiDeleteBin2Fill } from 'react-icons/ri';
import { ConfirmationModal } from '../../components';

export default function CartModal({
  isModalOpen,
  setIsModalOpen,
  cart,
  setCart,
}) {
  useEffect(() => {
    if (cart.length === 0) {
      setIsModalOpen(false);
    }
  }, [cart]);

  return (
    <>
      <Modal
        show={isModalOpen}
        onHide={() => setIsModalOpen(false)}
        backdrop="static"
        keyboard="false"
        size="lg"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Cart</Modal.Title>
        </Modal.Header>
        {/* <Alert
        variant="danger"
        show={isErrorAlertShown}
        onClose={setIsErrorAlertShown}
        dismissible
        className="my-0"
      >
        {errorMessage}
      </Alert> */}
        <Modal.Body>
          {cart.map((orderInfo, index) => (
            <Row
              key={index}
              className="align-items-center mb-3 border rounded p-2 mx-2"
            >
              <Col xs={2}>
                <img
                  height={'100px'}
                  src={
                    orderInfo.container.image === ''
                      ? 'https://img.tradeford.com/pimages/l/2/1025722.jpg'
                      : orderInfo.container.image
                  }
                  alt="Tank"
                />
              </Col>
              <Col xs={2}>
                Container:
                <h5 className="m-0 mb-1 ms-3 p-0">
                  {orderInfo.container.name}
                </h5>
                <span className="ms-3">&#8369;{orderInfo.container.price}</span>
              </Col>
              <Col xs={4}></Col>
              <Col xs={2} className="text-end">
                <span className="ms-3">
                  <strong>Refill: {orderInfo.refillRequest}KG</strong>
                </span>
              </Col>
              <Col xs={2} className="text-end">
                <Button
                  variant="danger"
                  onClick={() => {
                    console.log(cart.at(index));
                    console.log(index);
                    const newCart = [...cart];
                    const filteredNewCart = newCart.filter(
                      (cart, idx) => idx !== index
                    );
                    setCart(filteredNewCart);
                  }}
                >
                  <RiDeleteBin2Fill size={36} />
                </Button>
              </Col>
            </Row>
          ))}
          {/* <Modal.Footer>
            <Button
              variant="secondary"
              onClick={() => setIsModalOpen(false)}
              className="p-2 w-25"
            >
              Close
            </Button>
            <Button variant="success" className="p-2 w-25">
              Proceed
            </Button>
          </Modal.Footer> */}
        </Modal.Body>
      </Modal>
    </>
  );
}
