import React, { useContext, useEffect, useState } from 'react';
import { Col, Container, Form, Row } from 'react-bootstrap';
import { InputControl } from '../../components';
import { GlobalContext } from '../../App';
import { UserContext } from '../../utils/context/UserContext';
import { API_BOOLEAN_TRUE } from '../../utils/api/APIConstants';

export default function BranchSelection({ onSelectBranch }) {
  const { setIsToastOpen, setToast } = useContext(GlobalContext);
  const {
    profile,
    branchesList,
    loadAllBranches,
    isErrorAlertShown,
    setIsErrorAlertShown,
    errorMessage,
    setErrorMessage,
  } = useContext(UserContext);

  const [isLoading, setIsLoading] = useState(false);

  const [selectedBranch, setSelectedBranch] = useState('0');
  const [localBranchesList, setLocalBranchesList] = useState([]);

  const handleBranchChange = (id) => {
    setSelectedBranch(id);
    if (id !== '0') {
      const branch = localBranchesList.find((branch) => branch.id === id);
      onSelectBranch(branch);
    }
  };

  const handleInitialState = async () => {
    setIsLoading(true);
    await loadAllBranches();
    setLocalBranchesList(branchesList);
    setIsLoading(false);
  };

  useEffect(() => {
    if (localBranchesList.length === 0) {
      handleInitialState();
    }
  }, [branchesList]);

  return (
    <Container className="mt-5">
      <Row>
        <Col xs={{ span: 4, offset: 4 }}>
          <Form.Group className="mb-3" controlId="category">
            <Form.Label>Please select your branch:</Form.Label>
            <Form.Select
              aria-label="Branch Selection"
              value={selectedBranch}
              onChange={(e) => handleBranchChange(e.target.value)}
              disabled={isLoading}
            >
              <option value={'0'}>Select Branch</option>
              {localBranchesList
                .filter(
                  (branch) =>
                    branch.id !== API_BOOLEAN_TRUE &&
                    branch.isDeleted !== API_BOOLEAN_TRUE
                )
                .map((branch) => (
                  <option key={branch.id} value={branch.id}>
                    {branch.name}
                  </option>
                ))}
            </Form.Select>
          </Form.Group>
        </Col>
      </Row>
    </Container>
  );
}
