import React from 'react';
import { Form, InputGroup } from 'react-bootstrap';

export default function InputControl({
  type = 'text',
  placeholder,
  label,
  autoFocus = false,
  value,
  onChange,
  required = true,
  disabled = false,
  dropdownOptions,
  children,
}) {
  return (
    <>
      {type === 'name' && (
        <Form.Group className="mb-2">
          {label && <Form.Label>{label}</Form.Label>}
          <InputGroup>
            <Form.Control
              type="text"
              placeholder={placeholder}
              autoFocus={autoFocus}
              value={value}
              required={required}
              disabled={disabled}
              onChange={onChange}
              className="text-capitalize"
            />
            {children && <InputGroup.Text>{children}</InputGroup.Text>}
          </InputGroup>
        </Form.Group>
      )}
      {type === 'text' && (
        <Form.Group className="mb-2">
          {label && <Form.Label>{label}</Form.Label>}
          <InputGroup>
            <Form.Control
              type="text"
              placeholder={placeholder}
              autoFocus={autoFocus}
              value={value}
              required={required}
              disabled={disabled}
              onChange={onChange}
            />
            {children && <InputGroup.Text>{children}</InputGroup.Text>}
          </InputGroup>
        </Form.Group>
      )}
      {type === 'textarea' && (
        <Form.Group className="mb-2">
          {label && <Form.Label>{label}</Form.Label>}
          <InputGroup>
            <Form.Control
              as="textarea"
              rows={3}
              placeholder={placeholder}
              autoFocus={autoFocus}
              value={value}
              required={required}
              disabled={disabled}
              onChange={onChange}
            />
            {children && <InputGroup.Text>{children}</InputGroup.Text>}
          </InputGroup>
        </Form.Group>
      )}
      {type === 'mobile-number' && (
        <Form.Group className="mb-2">
          {label && <Form.Label>{label}</Form.Label>}
          <InputGroup className="mb-3">
            <InputGroup.Text id="mobile-number">+63</InputGroup.Text>
            <Form.Control
              type="number"
              placeholder={placeholder}
              autoFocus={autoFocus}
              value={value}
              required={required}
              disabled={disabled}
              onChange={onChange}
              aria-describedby="mobile-number"
            />
            {children && <InputGroup.Text>{children}</InputGroup.Text>}
          </InputGroup>
        </Form.Group>
      )}
      {type === 'telephone' && (
        <Form.Group className="mb-2">
          {label && <Form.Label>{label}</Form.Label>}
          <InputGroup>
            <Form.Control
              type="number"
              placeholder={placeholder}
              autoFocus={autoFocus}
              value={value}
              required={required}
              disabled={disabled}
              onChange={onChange}
            />
            {children && <InputGroup.Text>{children}</InputGroup.Text>}
          </InputGroup>
        </Form.Group>
      )}

      {type === 'dropdown' && (
        <Form.Group className="mb-2">
          {label && <Form.Label>{label}</Form.Label>}
          <Form.Select
            required={required}
            value={value}
            disabled={disabled}
            onChange={onChange}
          >
            <option value={0}>Select</option>
            {dropdownOptions.map((option) => (
              <option key={option.id} value={option.id}>
                {option.description}
              </option>
            ))}
          </Form.Select>
        </Form.Group>
      )}
    </>
  );
}
