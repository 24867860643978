import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Navigation } from '../../components';
import {
  AccessMaintenance,
  BranchesMaintenance,
  BulletTankMaintenance,
  BulletTankReport,
  CashAdvanceReport,
  CategoriesMaintenance,
  CustomerHistoryReport,
  CustomerMaintenance,
  Dashboard,
  ExpenseReport,
  IncomeStatementReport,
  OutletHistoryReport,
  OutletMaitenance,
  ProductsMaintenance,
  SalesEntry,
  SalesHistoryReport,
  SalesOrder,
  TimeIn,
  TimeKeepingReport,
  TimeOut,
  UserManagement,
} from '../../pages';
import {
  ACCESS_LEVEL_MAINTENANCE_ROUTE,
  BRANCH_MAINTENANCE_ROUTE,
  BULLET_TANK_HISTORY_REPORT_ROUTE,
  BULLET_TANK_MAINTENANCE_ROUTE,
  CASH_ADVANCE_REPORT_ROUTE,
  CATEGORY_MAINTENANCE_ROUTE,
  CUSTOMER_HISTORY_REPORT_ROUTE,
  CUSTOMER_LIST_MAINTENANCE_ROUTE,
  EXPENSE_REPORT_ROUTE,
  INCOME_STATEMENT_REPORT_ROUTE,
  OUTLET_HISTORY_REPORT_ROUTE,
  OUTLET_MAINTENANCE_ROUTE,
  PRODUCT_MAINTENANCE_ROUTE,
  SALES_ENTRY_ROUTE,
  SALES_HISTORY_REPORT_ROUTE,
  SALES_ORDER_ROUTE,
  TIME_IN_ROUTE,
  TIME_KEEPING_REPORT_ROUTE,
  TIME_OUT_ROUTE,
  USER_MANAGEMENT_ROUTE,
} from './routes';

export default function MainRoute() {
  return (
    <Navigation>
      <Routes>
        <Route path="/" exact={true} element={<Dashboard />} />

        <Route path={SALES_ORDER_ROUTE} element={<SalesOrder />} />
        <Route path={SALES_ENTRY_ROUTE} element={<SalesEntry />} />
        <Route path={USER_MANAGEMENT_ROUTE} element={<UserManagement />} />

        {/* Reports */}
        <Route
          path={BULLET_TANK_HISTORY_REPORT_ROUTE}
          element={<BulletTankReport />}
        />
        <Route
          path={CASH_ADVANCE_REPORT_ROUTE}
          element={<CashAdvanceReport />}
        />
        <Route
          path={CUSTOMER_HISTORY_REPORT_ROUTE}
          element={<CustomerHistoryReport />}
        />
        <Route path={EXPENSE_REPORT_ROUTE} element={<ExpenseReport />} />
        <Route
          path={INCOME_STATEMENT_REPORT_ROUTE}
          element={<IncomeStatementReport />}
        />
        <Route
          path={OUTLET_HISTORY_REPORT_ROUTE}
          element={<OutletHistoryReport />}
        />
        <Route
          path={SALES_HISTORY_REPORT_ROUTE}
          element={<SalesHistoryReport />}
        />
        <Route
          path={TIME_KEEPING_REPORT_ROUTE}
          element={<TimeKeepingReport />}
        />

        {/* Maintenance */}
        <Route
          path={BULLET_TANK_MAINTENANCE_ROUTE}
          element={<BulletTankMaintenance />}
        />
        <Route
          path={CATEGORY_MAINTENANCE_ROUTE}
          element={<CategoriesMaintenance />}
        />
        <Route
          path={PRODUCT_MAINTENANCE_ROUTE}
          element={<ProductsMaintenance />}
        />
        <Route
          path={CUSTOMER_LIST_MAINTENANCE_ROUTE}
          element={<CustomerMaintenance />}
        />
        <Route
          path={ACCESS_LEVEL_MAINTENANCE_ROUTE}
          element={<AccessMaintenance />}
        />
        <Route path={OUTLET_MAINTENANCE_ROUTE} element={<OutletMaitenance />} />
        <Route
          path={BRANCH_MAINTENANCE_ROUTE}
          element={<BranchesMaintenance />}
        />

        {/* Time Keeping */}
        <Route path={TIME_IN_ROUTE} element={<TimeIn />} />
        <Route path={TIME_OUT_ROUTE} element={<TimeOut />} />

        <Route path="*" element={<Navigate to="/" />} />
      </Routes>
    </Navigation>
  );
}
