export const outletHistoryReportHeader = [
  {
    title: 'ID',
    dataIndex: 'id',
    key: 'id',
    sorter: (a, b) => a.id - b.id,
    fixed: 'left',
  },
  {
    title: 'Branch',
    dataIndex: 'field1',
    key: 'field1',
    sorter: (a, b) => a.field1.localeCompare(b.field1),
  },
  {
    title: 'Description',
    dataIndex: 'field2',
    key: 'field2',
    sorter: (a, b) => a.field2.localeCompare(b.field2),
  },
  {
    title: 'Amount',
    dataIndex: 'field3',
    key: 'field3',
    sorter: (a, b) => a.field3.localeCompare(b.field3),
  },
];
