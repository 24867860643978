import React from 'react';
import { Button, Card, ListGroup } from 'react-bootstrap';
import { IMAGE_URL_BASE_PATH } from '../../utils/api/APIConstants';
import { CustomerDefault } from '../../utils/data/_index';

export default function CustomerCard({
  customerDetails = CustomerDefault,
  onSelect,
}) {
  return (
    <Card
      style={{ width: '16rem', cursor: 'pointer' }}
      onClick={() => onSelect(customerDetails)}
      className="m-3 py-2 text-center"
    >
      {customerDetails.image && (
        <Card.Img
          variant="top"
          src={IMAGE_URL_BASE_PATH + customerDetails.image}
          className="p-4"
        />
      )}
      <Card.Body>
        {customerDetails.firstName &&
          customerDetails.middleName &&
          customerDetails.lastName && (
            <Card.Title>
              {`${
                customerDetails.firstName
              } ${customerDetails.middleName.charAt(0)}. ${
                customerDetails.lastName
              }`}
            </Card.Title>
          )}

        {customerDetails.address && (
          <Card.Text>{customerDetails.address}</Card.Text>
        )}
      </Card.Body>
      <ListGroup className="list-group-flush">
        <ListGroup.Item>{customerDetails.mobileNumber}</ListGroup.Item>
        {customerDetails.telNumber && (
          <ListGroup.Item>{customerDetails.telNumber}</ListGroup.Item>
        )}
        {customerDetails.gender && (
          <ListGroup.Item className="text-capitalize">
            {customerDetails.gender}
          </ListGroup.Item>
        )}
        {customerDetails.maritalStatus && (
          <ListGroup.Item className="text-capitalize">
            {customerDetails.maritalStatus}
          </ListGroup.Item>
        )}
      </ListGroup>
      <Card.Footer>
        <Button
          variant="success"
          onClick={() => onSelect(customerDetails)}
          size="lg"
        >
          It's me!
        </Button>
      </Card.Footer>
    </Card>
  );
}
