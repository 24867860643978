import React from 'react';
import { Button, Col, Modal, Row } from 'react-bootstrap';

export default function TimeKeepingModal({
  isModalOpen,
  setIsModalOpen,
  handleConfirm,
  image,
  time,
  profile,
}) {
  return (
    <Modal
      show={isModalOpen}
      onHide={() => setIsModalOpen(false)}
      backdrop="static"
      keyboard="false"
      centered
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Time Keeping</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col className="text-center">
            <p>
              User: {profile.firstName} {profile.lastName}
            </p>
            <p>Time: {time}</p>
            <img src={image} alt="selected" className="w-75" />
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={() => setIsModalOpen(false)}>
          Close
        </Button>
        <Button variant="success" onClick={handleConfirm}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
}
