import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Login, SalesOrder } from '../../pages';
import { HOME_ROUTE, SALES_ORDER_ROUTE } from './routes';

export default function AuthRoute() {
  return (
    <Routes>
      <Route path={HOME_ROUTE} exact={true} element={<Login />} />
      <Route path={SALES_ORDER_ROUTE} element={<SalesOrder />} />
      <Route path="*" element={<Navigate to="/" />} />
    </Routes>
  );
}
